import React, { useRef } from 'react';
import AnimatedAngle from 'components/Animated/AnimatedAngle';
import { Box } from '@mui/material';
import { useScrollDown } from 'hooks/useScrollDown';
import { motion } from 'framer-motion';

const MainJumbotron = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = useScrollDown(containerRef);

  return (
    <Box ref={containerRef} sx={{ position: 'relative', height: '100vh' }}>
      <Box
        sx={{
          position: 'absolute',
          top: {
            md: '45%',
            xs: '30%',
          },
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Box
          component={motion.h1}
          initial={{ opacity: 0, x: 30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          sx={{
            fontStyle: 'italic',
            fontSize: {
              md: '3.5rem',
              xs: '2rem',
            },
            textAlign: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          Growth
          <Box component="span" sx={{ color: 'secondary.main' }}>
            .
          </Box>{' '}
          And Share
        </Box>
        <Box
          component={motion.h2}
          initial={{ opacity: 0, x: -30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          sx={{ textAlign: 'center', fontWeight: 200, fontSize: '1.4rem', fontStyle: 'italic' }}
        >
          This is <strong>Lubycon</strong>
        </Box>
      </Box>
      <Box sx={{ position: 'absolute', bottom: '20%', left: '50%', transform: 'translateX(-50%)' }}>
        <motion.div
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <AnimatedAngle onClick={scrollToBottom} />
        </motion.div>
      </Box>
    </Box>
  );
};

export default MainJumbotron;
