import { Container, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import Emoji from 'components/Utils/Emoji';
import { motion } from 'framer-motion';
import { ImpressionArea } from '@lubycon/react';
import FadeUp from 'components/Animated/FadeUp';
import { useAllMembers } from 'hooks/useAllMembers';
import MemberCard from 'components/Cards/MemberCard';
import { deepPurple } from '@mui/material/colors';

const variants = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const IntroSection = () => {
  const members = useAllMembers('lubycon');
  const [visible, setVisible] = useState(false);

  return (
    <Container component="section" sx={{ paddingTop: 10, paddingBottom: 10 }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ position: 'relative' }}>
        <Grid
          item
          xs={12}
          sm
          sx={{
            paddingTop: 0,
          }}
        >
          <ImpressionArea onImpressionStart={() => setVisible(true)}>
            <motion.div variants={variants} initial="hidden" animate={visible ? 'visible' : 'hidden'}>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom={true}
                sx={{
                  fontWeight: 'bold',
                  fontSize: {
                    md: 'h4.fontSize',
                    xs: 'h6.fontSize',
                  },
                }}
              >
                안녕하세요. 우리는 루비콘 입니다 <Emoji emoji="👋" name="Hi" />
              </Typography>
            </motion.div>
            <motion.div
              variants={variants}
              initial="hidden"
              animate={visible ? 'visible' : 'hidden'}
              transition={{ delay: 0.3 }}
            >
              <Typography sx={{ marginBottom: 10 }}>
                루비콘은 국내 IT업계에 선한 영향을 만들어내고 싶은 개발자들과 디자이너들이 모인 팀이에요.
                <br />
                우리는 지속적으로 새로운 것들에 도전하며 실패하고, 그 과정 속에서 배운 것들을 공유해요. 그리고 이런 공유
                활동은 루비콘 팀 뿐만 아니라 팀 외부에서도 이루어지고 있어요.
              </Typography>
            </motion.div>
          </ImpressionArea>
        </Grid>
        <Grid container spacing={2}>
          {members.map(mentor => (
            <Grid item key={mentor.id} xs={6} sm={3}>
              <FadeUp>
                <MemberCard member={mentor} backgroundColor={deepPurple['100']} />
              </FadeUp>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default IntroSection;
