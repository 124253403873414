import { Container, Grid, Typography, Box, useTheme, Theme } from '@mui/material';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import FadeUp from 'components/Animated/FadeUp';
import BenefitCard from 'components/Cards/BenefitCard';
import { ImpressionArea } from '@lubycon/react';
import { grey } from '@mui/material/colors';

const activities = [
  {
    title: '스터디',
    image: 'https://assets.lubycon.io/illusts/books.png',
    description: `우리는 관심있는 분야가 있다면 함께 스터디하고 싶은 멤버들을 모아서 자체 스터디를 하고 있어요.\n최근 팀 내에서 가장 핫한 주제는 "나의 성장 뿐 아니라 팀의 성장을 이끌 수 있는 사람이 되는 것"입니다.`,
  },
  {
    title: '루비콘 샌드박스',
    image: 'https://assets.lubycon.io/illusts/roadsign.png',
    description: `우리는 멘토링을 통해 아직 성장의 방향을 잡지 못한 분들을 도와드리고 있어요.\n\n우리가 지금까지 쌓아왔던 모든 경험들이 다른 사람들에게 분명 도움이 될 것이라고 믿거든요.`,
  },
  {
    title: '커뮤니티',
    image: 'https://assets.lubycon.io/illusts/networking.png',
    description: `루비콘 팀이 운영하는 커뮤니티인 루비콘 허브는 함께 프로젝트를 진행하는 팀과 같은 직군이 모인 챕터로 구성되어 있어요.\n많은 사람들이 빠른 정보 공유와 투명한 커뮤니케이션을 통해 성장하기를 바라요.`,
  },
  {
    title: '프로젝트',
    image: 'https://assets.lubycon.io/illusts/laptop.png',
    description:
      '루비콘 팀의 프로젝트는 스스로 필요한 제품을 개발하는 것 뿐만 아니라 UI Kit처럼 다른 사람들의 생산성을 높힐 수 있는 제품을 개발하기도 해요.',
  },
];

const ActivitySection = () => {
  const [visible, setVisible] = useState(false);
  const { spacing } = useTheme<Theme>();

  return (
    <Box
      component="section"
      sx={{
        padding: {
          md: `${spacing(15)} 0`,
          xs: `${spacing(10)} 0`,
        },
        backgroundColor: grey['100'],
      }}
    >
      <Container>
        <ImpressionArea onImpressionStart={() => setVisible(true)}>
          <motion.div
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 50 },
            }}
            initial="hidden"
            animate={visible ? 'visible' : 'hidden'}
          >
            <Typography
              variant="h4"
              component="h2"
              gutterBottom={true}
              sx={{
                fontWeight: 'bold',
                fontSize: {
                  md: 'h4.fontSize',
                  xs: 'h6.fontSize',
                },
              }}
            >
              함께 성장하고 공유하기
            </Typography>
          </motion.div>
          <Box
            component={motion.div}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 50 },
            }}
            initial="hidden"
            animate={visible ? 'visible' : 'hidden'}
            transition={{ delay: 0.3 }}
            sx={{ marginBottom: 5 }}
          >
            <Typography>
              루비콘 팀의 이니셔티브는 <strong>성장과 공유</strong>에요. 루비콘 팀은 이 이니셔티브를 달성하기 위한
              여러가지 활동을 하고 있어요.
              <br />
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {activities.map(({ title, image, description }, index) => (
              <Grid key={index} item xs={12} sm={3}>
                <FadeUp>
                  <BenefitCard title={title} image={image} description={description} />
                </FadeUp>
              </Grid>
            ))}
          </Grid>
        </ImpressionArea>
      </Container>
    </Box>
  );
};

export default ActivitySection;
