import React, { useEffect } from 'react';
import Page from 'components/Layout/Page';
import MainJumbotron from './MainJumbotron';
import ActivitySection from './ActivitySection';
import ContactSection from './ContactSection';
import IntroSection from './IntroSection';
import { mainPageLogger } from 'utils/loggers';

const HomePage = () => {
  useEffect(() => {
    mainPageLogger.view();
  }, []);

  return (
    <Page>
      <MainJumbotron />
      <IntroSection />
      <ActivitySection />
      <ContactSection />
    </Page>
  );
};

export default HomePage;
