import { Button, Container, Typography, Theme, Box, useTheme } from '@mui/material';
import React from 'react';

const ContactSection = () => {
  const { spacing } = useTheme<Theme>();

  return (
    <Box
      sx={{
        padding: {
          md: `${spacing(15)} 0`,
          xs: `${spacing(10)} 0`,
        },
        textAlign: 'center',
      }}
    >
      <Container>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom={true}
          sx={{
            fontWeight: 'bold',
            fontSize: {
              md: 'h4.fontSize',
              xs: 'h6.fontSize',
            },
          }}
        >
          문의하기
        </Typography>
        <Typography sx={{ marginBottom: 6 }}>
          제휴 문의나 루비콘이 진행하고 있는 프로젝트에 대한 문의는 help@lubycon.io로 보내주세요!
        </Typography>
        <Button href="mailto:help@lubycon.io" target="_blank" variant="contained" color="primary" size="large">
          지금 바로 문의하기
        </Button>
      </Container>
    </Box>
  );
};

export default ContactSection;
