import React, { ComponentProps } from 'react';
import { Box } from '@mui/material';

type Direction = 'left' | 'right' | 'down' | 'up';
const directionRotate: { [key in Direction]: string } = {
  left: '-45deg',
  right: '135deg',
  up: '45deg',
  down: '-135deg',
};

interface Props extends ComponentProps<typeof Box> {
  size?: number;
  direction?: Direction;
}
const AnimatedAngleIcon = ({ size = 40, direction = 'down', ...rest }: Props) => {
  return (
    <Box
      component="a"
      sx={{
        display: 'inline-block',
        width: `${size}px`,
        height: `${size}px`,
        textIndent: '-9999px',
        borderTop: 2,
        borderLeft: 2,
        borderColor: 'InfoText',
        transition: 'all 0.3s ease-in-out',
        color: 'transparent',
        textDecoration: 'none',
        transform: `rotate(${directionRotate[direction]})`,
        cursor: 'pointer',
        '&:hover': {
          borderColor: 'palette.text.primary',
          borderWidth: 5,
        },
        '&::before': {
          display: 'block',
          height: '200%',
          width: '200%',
          marginLeft: '50%',
          marginTop: '-50%',
          content: '""',
          transform: 'rotate(45deg)',
        },
      }}
      {...rest}
    />
  );
};

export default AnimatedAngleIcon;
