import { RefObject, useCallback, useState } from 'react';
import { useResizeObserver } from './useResizeObserver';

export interface ScrollDownOptions {
  gutter?: number;
}
export function useScrollDown(ref: RefObject<HTMLElement>, option?: ScrollDownOptions) {
  const { gutter = 0 } = option ?? {};
  const [scrollTarget, setScrollTarget] = useState(0);

  const scrollToTarget = useCallback(() => {
    window.scroll({ top: scrollTarget + gutter, behavior: 'smooth' });
  }, [gutter, scrollTarget]);

  const updateScrollTarget = useCallback(() => {
    if (ref.current) {
      const el = ref.current;
      const scrollTarget = el.offsetTop + el.clientHeight;
      setScrollTarget(scrollTarget);
    }
  }, [ref]);

  useResizeObserver(ref, updateScrollTarget);

  return scrollToTarget;
}
